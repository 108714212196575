<template>
  <div>
    <!-- Basic data -->
    <h4 class="text-primary">
      {{ $t('Contracts.BasicData') }}
    </h4>
    <b-card v-if="item">
      <b-form-group :label="$t('Name')">
        <b-form-input
          v-model="item.name"
          type="text"
        />
      </b-form-group>

      <sw-select :name="$t('ai.reports.Version')">
        <v-select
          v-model="item.model"
          :options="[
            'gpt-4o-2024-05-13',
            'gpt-4o',
            'gpt-4-vision-preview',
            'gpt-4-turbo-preview',
            'gpt-4-turbo-2024-04-09',
            'gpt-4-turbo',
            'gpt-4-1106-preview',
            'gpt-4-0613',
            'gpt-4-0125-preview',
            'gpt-4',
            'gpt-3.5-turbo-16k-0613',
            'gpt-3.5-turbo-16k',
            'gpt-3.5-turbo-1106',
            'gpt-3.5-turbo-0613',
            'gpt-3.5-turbo-0125',
            'gpt-3.5-turbo',
          ]"
          :clearable="false"
        />
      </sw-select>

      <b-form-group>
        <template #label>
          {{ $t('AIModuleDescription') }}
          <feather-icon v-b-tooltip
                        icon="AlertCircleIcon"
                        class="text-primary ml-25"
                        :title="$t('AiModuleDescriptionTip')"
          />
        </template>
        <b-form-textarea
          v-model="item.instructions"
          rows="6"
        />
      </b-form-group>

      <b-form-checkbox
        v-model="item.visionEnabled"
        variant="primary"
      >{{ $t('VisionService') }}
        <feather-icon v-b-tooltip
                      icon="AlertCircleIcon"
                      class="text-primary ml-25"
                      :title="$t('VisionServiceDescription')"
        />
      </b-form-checkbox>
      <!--      <b-row>-->
      <!--        <b-col-->
      <!--          sm="12"-->
      <!--          md="6"-->
      <!--        >-->
      <!--          <sw-select :name="$t('DefineTheEffectOfTheAIIntegration')">-->
      <!--            <v-select-->
      <!--              v-model="item.effectOfIntegration"-->
      <!--              :options="effects"-->
      <!--            >-->
      <!--              <template #no-options>-->
      <!--                {{ $t('NoOptions') }}-->
      <!--              </template>-->
      <!--            </v-select>-->
      <!--          </sw-select>-->
      <!--        </b-col>-->
      <!--        <b-col-->
      <!--          v-if="item.effectOfIntegration && item.effectOfIntegration.value !== 'COMPLAINT'"-->
      <!--          sm="12"-->
      <!--          md="6"-->
      <!--        >-->
      <!--          <sw-select :name="$t('SelectCRMModulesForIntegration')">-->
      <!--            <v-select-->
      <!--              v-model="item.modules"-->
      <!--              :options="modules"-->
      <!--            >-->
      <!--              <template #no-options>-->
      <!--                {{ $t('NoOptions') }}-->
      <!--              </template>-->
      <!--            </v-select>-->
      <!--          </sw-select>-->
      <!--        </b-col>-->
      <!--        <b-col-->
      <!--          v-if="item.effectOfIntegration && item.effectOfIntegration.value !== 'COMPLAINT' && item.modules"-->
      <!--          sm="12"-->
      <!--        >-->
      <!--          <sw-select :name="$t('SelectCategoriesToShare')">-->
      <!--            <v-select-->
      <!--              :options="item.modules.options"-->
      <!--              multiple-->
      <!--            >-->
      <!--              <template #no-options>-->
      <!--                {{ $t('NoOptions') }}-->
      <!--              </template>-->
      <!--            </v-select>-->
      <!--          </sw-select>-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <b-overlay
        :show="loading"
        no-wrap
        spinner-variant="primary"
      />
    </b-card>

    <b-card>
      <b-overlay
        :show="loading"
        no-wrap
        spinner-variant="primary"
      />

      <b-row>
        <b-col sm="12"
               md="6"
        >
          <b-form-group class="px-2">
            <template #label>
              {{ $t('SetTemperature') }}
              <feather-icon v-b-tooltip
                            icon="AlertCircleIcon"
                            class="text-primary ml-25"
                            :title="$t('TemperatureDescription')"
              />
            </template>
            <vue-slider
              v-model="item.temperature"
              :contained="true"
              :interval="0.01"
              :min="0"
              :max="2"
              :marks="{ 0: $t('Small'), 1: $t('Balanced'), 2: $t('High') }"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12"
               md="6"
        >
          <b-form-group class="px-2">
            <template #label>
              {{ $t('Top P') }}
              <feather-icon v-b-tooltip
                            icon="AlertCircleIcon"
                            class="text-primary ml-25"
                            :title="$t('TopPDescription')"
              />
            </template>
            <vue-slider
              v-model="item.topP"
              :contained="true"
              :interval="0.01"
              :min="0"
              :max="1"
              :marks="{ 0: $t('Small'), .5: $t('Balanced'), 1: $t('High') }"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- TOPIC -->
    <div class="d-flex align-items-center mb-50">
      <h4 id="topic-header"
          class="text-primary mb-0 mr-50"
      >
        {{ $t('TopicsForAi') }}

        <feather-icon v-b-tooltip
                      icon="AlertCircleIcon"
                      class="text-primary ml-25"
                      :title="$t('AssistantAiFunctionsTooltip')"
        />
      </h4>

      <b-button size="sm"
                variant="primary"
                @click="openTopicModal"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}
      </b-button>
    </div>

    <b-card v-if="!topics.length">
      <div class="p-50 text-center text-primary">
        <feather-icon icon="XCircleIcon" />
        {{ $t('NoData') }}
      </div>
    </b-card>
    <b-row v-else>
      <b-col
        v-for="(topic, topicIndex) in topics"
        :key="`topic_${topicIndex}`"
        sm="12"
        md="4"
      >
        <b-card header-class="py-50 px-1 w-100">
          <template #header>
            <div class="d-flex align-items-center justify-content-between w-100">
              <h5>
                <b-avatar variant="primary"
                          class="mr-50"
                >
                  <feather-icon size="19"
                                :icon="aiTopicsByValue(topic.type).icon"
                  />
                </b-avatar>
                {{ $t(aiTopicsByValue(topic.type).label) }}
              </h5>
              <div>
                <b-button class="mr-25"
                          size="sm"
                          variant="flat-warning"
                          @click="openTopicModal(topicIndex, { ...topic })"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button size="sm"
                          variant="flat-danger"
                          @click="removeTopic({ ...topic }, topicIndex)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </div>
          </template>
          <small v-if="topic"
                 v-b-tooltip
                 class="text-muted"
                 :title="$t(aiTopicsByValue(topic.type).text)"
          >
            {{ $t(aiTopicsByValue(topic.type).text) | truncate(150) }}
          </small>
        </b-card>
      </b-col>
    </b-row>

    <!-- Upload data -->
    <h4 class="text-primary">
      {{ $t('KnowledgeBase') }}
      <feather-icon v-b-tooltip
                    icon="AlertCircleIcon"
                    class="text-primary ml-25 mr-50"
                    :title="$t('BaseKnowledgeDescriptionTip')"
      />
      <b-button size="sm"
                variant="primary"
                @click="showFileModal = true"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}
      </b-button>

      <!--      <feather-icon-->
      <!--        v-b-tooltip-->
      <!--        icon="AlertCircleIcon"-->
      <!--        class="text-primary ml-25"-->
      <!--        :title="$t('BaseKnowledgeDescriptionTip')"-->
      <!--      />-->
    </h4>
    <add-sales-ai-file-modal :show="showFileModal"
                             @hide-modal="showFileModal = false"
                             @save-modal="addNewSalesAiFile"
    />

    <edit-ai-connection-data
      body-text="HereYouCanUploadDataInTheFormOfQuestionAndAnswerFilesBasedOnWhichAIWillGenerateResponsesDownloadATemplateToPrepareTheFileCorrectly"
      heading-text="offer.website.QAndA"
      icon-size="25"
      icon-header
      show-body
      show-sample
      :show-add="false"
      :add-header="true"
    />
    <!--    <b-card>-->
    <!--      <b-row>-->
    <!--        <b-col-->
    <!--          sm="12"-->
    <!--          md="6"-->
    <!--          class="d-flex align-items-center"-->
    <!--        >-->
    <!--          <edit-ai-connection-data-->
    <!--            body-text="HereYouCanUploadDataInTheFormOfQuestionAndAnswerFilesBasedOnWhichAIWillGenerateResponsesDownloadATemplateToPrepareTheFileCorrectly"-->
    <!--            heading-text="offer.website.QAndA"-->
    <!--            icon-size="25"-->
    <!--            icon-header-->
    <!--            show-body-->
    <!--            :show-sample="false"-->
    <!--            add-header-->
    <!--            @add="showQandAModal = true"-->
    <!--          />-->
    <!--        </b-col>-->
    <!--        <b-col-->
    <!--          sm="12"-->
    <!--          md="6"-->
    <!--          class="d-flex align-items-center"-->
    <!--        >-->
    <!--          <div-->
    <!--            class="text-center ml-2 cursor-pointer"-->
    <!--            @click="showTextModal = true"-->
    <!--          >-->
    <!--            <b-avatar-->
    <!--              size="90"-->
    <!--              variant="light-primary"-->
    <!--            >-->
    <!--              <feather-icon-->
    <!--                size="35"-->
    <!--                icon="Edit3Icon"-->
    <!--              />-->
    <!--            </b-avatar>-->
    <!--            <div class="font-weight-bolder text-primary font-medium-2 mt-25">-->
    <!--              {{ $t('Text').toUpperCase() }}-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <div-->
    <!--            class="text-center ml-2 cursor-pointer"-->
    <!--            @click="showUrlModal = true"-->
    <!--          >-->
    <!--            <b-avatar-->
    <!--              size="90"-->
    <!--              variant="light-primary"-->
    <!--            >-->
    <!--              <feather-icon-->
    <!--                size="35"-->
    <!--                icon="LinkIcon"-->
    <!--              />-->
    <!--            </b-avatar>-->
    <!--            <div class="font-weight-bolder text-primary font-medium-2 mt-25">-->
    <!--              URL-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <div-->
    <!--            class="text-center ml-2 cursor-pointer"-->
    <!--            @click="showPdfModal = true"-->
    <!--          >-->
    <!--            <b-avatar-->
    <!--              size="90"-->
    <!--              variant="light-primary"-->
    <!--            >-->
    <!--              <feather-icon-->
    <!--                size="35"-->
    <!--                icon="FileTextIcon"-->
    <!--              />-->
    <!--            </b-avatar>-->
    <!--            <div class="font-weight-bolder text-primary font-medium-2 mt-25">-->
    <!--              PDF-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </b-col>-->
    <!--      </b-row>-->

    <b-overlay
      :show="loading"
      no-wrap
      spinner-variant="primary"
    />
    <!--    </b-card>-->
    <div class="position-relative">
      <b-card v-if="!salesAiFiles.length">
        <div class="p-50 text-center text-primary">
          <feather-icon icon="XCircleIcon" />
          {{ $t('NoData') }}
        </div>
      </b-card>
      <b-form-checkbox-group v-else
                             v-model="itemSelectedSalesAiFileIds"
                             variant="primary"
      >
        <b-row>
          <b-col
            v-for="(salesAiFile, fileIndex) in salesAiFiles"
            :key="`files_${fileIndex}`"
            sm="12"
            md="6"
            lg="3"
          >
            <label class="w-100"
                   :for="`sales_file_${salesAiFile.id}`"
            >
              <b-card no-body
                      class="border"
                      :class="{ 'border-primary': itemSelectedSalesAiFileIds.includes(salesAiFile.id) }"
              >
                <div class="d-flex justify-content-between align-items-center w-100 p-1">
                  <div v-if="salesAiFile.file"
                       class="d-flex align-items-center"
                  >
                    <b-avatar variant="primary"
                              class="mr-50"
                    >
                      <feather-icon size="19"
                                    :icon="fileIcon(salesAiFile.file.extension)"
                      />
                    </b-avatar>
                    <div>
                      <div v-b-tooltip
                           class="font-weight-bold"
                           :title="(salesAiFile.file.name || salesAiFile.file.filename)"
                      >
                        {{ (salesAiFile.file.name || salesAiFile.file.filename) | truncate(32) }}
                      </div>
                      <small v-if="salesAiFile.createdAt"
                             v-b-tooltip
                             class="text-muted"
                             :title="$t('CreatedAt')"
                      >{{ formatDate(salesAiFile.createdAt.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}</small>
                    </div>
                  </div>

                  <b-form-checkbox :id="`sales_file_${salesAiFile.id}`"
                                   variant="primary"
                                   :value="salesAiFile.id"
                  />
                </div>
              </b-card>
            </label>
          </b-col>
        </b-row>
      </b-form-checkbox-group>

      <b-overlay
        :show="loading"
        no-wrap
        spinner-variant="primary"
      />

      <b-overlay :show="!loading && !allowFiles"
                 no-wrap
      >
        <template #overlay>
          <div class="d-flex align-items-center text-primary">
            <feather-icon icon="LockIcon"
                          size="28"
            />
            <span class="font-medium-1 ml-25">{{ $t('FilesNotAllowed') }}</span>
          </div>
        </template>
      </b-overlay>
    </div>

    <!--    <div v-if="item.effectOfIntegration && item.effectOfIntegration.value === 'COMPLAINT'">-->
    <!--      <h4 class="text-primary">-->
    <!--        {{ $t('SubmitDataToSalesWizardAI') }}-->
    <!--      </h4>-->
    <!--      <b-card>-->
    <!--        <b-alert-->
    <!--          show-->
    <!--          variant="primary"-->
    <!--        >-->
    <!--          <div class="alert-body">-->
    <!--            <feather-icon icon="AlertCircleIcon" />-->
    <!--            {{ $t('DefinitionOfRequiredData') }}-->
    <!--          </div>-->
    <!--        </b-alert>-->

    <!--        <b-row>-->
    <!--          <b-col-->
    <!--            v-for="(optionItem, optionIndex) in options"-->
    <!--            :key="`option_${optionIndex}`"-->
    <!--            sm="12"-->
    <!--            md="6"-->
    <!--            lg="4"-->
    <!--            class="mb-1"-->
    <!--          >-->
    <!--            <div class="d-flex justify-content-between px-2">-->
    <!--              <div>-->
    <!--                {{ optionIndex + 1 }}. {{ optionItem.label }}-->
    <!--              </div>-->
    <!--              <div class="d-flex align-items-center justify-content-end">-->
    <!--                {{ optionItem.oblig ? $t('Obligatory') : $t('NotObligatory') }}-->

    <!--                <b-form-checkbox-->
    <!--                  v-model="optionItem.oblig"-->
    <!--                  switch-->
    <!--                  variant="primary"-->
    <!--                  class="mx-25 flex-grow-1"-->
    <!--                />-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </b-col>-->
    <!--        </b-row>-->
    <!--      </b-card>-->
    <!--    </div>-->

    <!-- Welcome message -->
    <!--    <h4 class="text-primary">-->
    <!--      {{ $t('WelcomeMessage') }}-->
    <!--    </h4>-->
    <!--    <b-card>-->
    <!--      <b-form-textarea rows="6"-->
    <!--                       disabled-->
    <!--      />-->

    <!--      <b-overlay-->
    <!--          :show="loading"-->
    <!--          no-wrap-->
    <!--          spinner-variant="primary"-->
    <!--      />-->
    <!--    </b-card>-->

    <!-- Ai settings -->
    <!--    <h4 v-if="false"-->
    <!--        class="text-primary"-->
    <!--    >-->
    <!--      {{ $t('OpenAISettings') }}-->
    <!--    </h4>-->
    <!--    <b-row v-if="false">-->
    <!--      <b-col-->
    <!--        v-for="(configItem, configIndex) in config"-->
    <!--        :key="`config_${configIndex}`"-->
    <!--        sm="12"-->
    <!--        md="6"-->
    <!--        lg="4"-->
    <!--        class="mb-2"-->
    <!--      >-->
    <!--        <b-card class="px-2">-->
    <!--          <b-form-group>-->
    <!--            <template #label>-->
    <!--              {{ $t(configItem.label) }}-->
    <!--              <feather-icon-->
    <!--                v-if="configItem.tooltip"-->
    <!--                v-b-tooltip-->
    <!--                icon="AlertCircleIcon"-->
    <!--                class="text-primary"-->
    <!--                :title="$t(configItem.tooltip)"-->
    <!--              />-->
    <!--            </template>-->

    <!--            <b-form-input-->
    <!--              v-if="configItem.type === 'INPUT'"-->
    <!--              type="number"-->
    <!--            />-->
    <!--            <v-select-->
    <!--              v-else-if="configItem.type === 'SELECT'"-->
    <!--              :options="configItem.options"-->
    <!--            />-->
    <!--            <vue-slider-->
    <!--              v-else-if="configItem.type === 'SLIDER'"-->
    <!--              v-bind="configItem.config"-->
    <!--              :contained="true"-->
    <!--              :value="item[configItem.key]"-->
    <!--              @change="item[configItem.key] = $event"-->
    <!--            />-->
    <!--          </b-form-group>-->

    <!--          <b-overlay-->
    <!--            :show="loading"-->
    <!--            no-wrap-->
    <!--            spinner-variant="primary"-->
    <!--          />-->
    <!--        </b-card>-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <!-- Conversion effects -->
    <!--    <h4 v-if="false"-->
    <!--        class="text-primary"-->
    <!--    >-->
    <!--      {{ $t('ConversionEffects') }}-->
    <!--    </h4>-->

    <b-button variant="primary"
              :disabled="loading"
              @click="saveItem"
    >
      {{ $t('Save') }}
    </b-button>

    <!-- Modals -->
    <!--    <b-modal-->
    <!--      v-model="showQandAModal"-->
    <!--      :title="$t('offer.website.QAndA')"-->
    <!--      hide-footer-->
    <!--    >-->
    <!--      <edit-ai-connection-data-->
    <!--        body-text="HereYouCanUploadDataInTheFormOfQuestionAndAnswerFilesBasedOnWhichAIWillGenerateResponsesDownloadATemplateToPrepareTheFileCorrectly"-->
    <!--        heading-text="offer.website.QAndA"-->
    <!--        icon-size="25"-->
    <!--        icon-header-->
    <!--        show-body-->
    <!--        :show-sample="false"-->
    <!--        :add-header="false"-->
    <!--        :show-add="false"-->
    <!--      />-->
    <!--      <drag-drop-upload accept=".pdf,.txt"-->
    <!--                        @onChangeFiles="data.files = $event"-->
    <!--      />-->
    <!--      <b-button-->
    <!--        class="mt-50"-->
    <!--        size="sm"-->
    <!--        variant="primary"-->
    <!--        @click="pushNewFiles(data.files); closeModals()"-->
    <!--      >-->
    <!--        <feather-icon icon="PlusIcon" />-->
    <!--        {{ $t('Add') }}</b-button>-->
    <!--    </b-modal>-->

    <!--    <b-modal-->
    <!--      v-model="showTextModal"-->
    <!--      :title="$t('Text')"-->
    <!--      hide-footer-->
    <!--    >-->

    <!--      <b-form-textarea-->
    <!--        v-model="data.text"-->
    <!--        rows="6"-->
    <!--      />-->
    <!--      <b-button-->
    <!--        class="mt-50"-->
    <!--        size="sm"-->
    <!--        variant="primary"-->
    <!--        @click="item.data.push({ ...data, type: 'TEXT', createdAt: { date: new Date() } }); closeModals()"-->
    <!--      >-->
    <!--        <feather-icon icon="PlusIcon" />-->
    <!--        {{ $t('Add') }}</b-button>-->
    <!--    </b-modal>-->

    <!--    <b-modal-->
    <!--      v-model="showUrlModal"-->
    <!--      title="URL"-->
    <!--      hide-footer-->
    <!--    >-->
    <!--      <b-form-input-->
    <!--        v-model="data.url"-->
    <!--        type="text"-->
    <!--      />-->
    <!--      <b-button-->
    <!--        class="mt-50"-->
    <!--        size="sm"-->
    <!--        variant="primary"-->
    <!--        @click="item.data.push({ ...data, type: 'URL', createdAt: { date: new Date() } }); closeModals()"-->
    <!--      >-->
    <!--        <feather-icon icon="PlusIcon" />-->
    <!--        {{ $t('Add') }}</b-button>-->
    <!--    </b-modal>-->

    <!--    <b-modal-->
    <!--      v-model="showPdfModal"-->
    <!--      title="PDF"-->
    <!--      hide-footer-->
    <!--    >-->
    <!--      <drag-drop-upload @onChangeFiles="data.files = $event" />-->
    <!--      <b-button-->
    <!--        class="mt-50"-->
    <!--        size="sm"-->
    <!--        variant="primary"-->
    <!--        @click="item.files.push(...data.files); closeModals()"-->
    <!--      >-->
    <!--        <feather-icon icon="PlusIcon" />-->
    <!--        {{ $t('Add') }}</b-button>-->
    <!--    </b-modal>-->

    <b-modal
      v-model="topicModal.show"
      :title="topicModal.index > -1 || topicModal.item.id ? $t('Edit') : $t('Add')"
      hide-footer
      @hidden="closeTopicModal"
      @hide="closeTopicModal"
    >
      <b-alert show
               variant="primary"
      >
        <div class="alert-body">
          <feather-icon icon="AlertCircleIcon" />
          {{ $t('AssistantAiFunctionsTooltip') }}
        </div>
      </b-alert>

      <b-form-radio-group v-model="topicModal.item.type"
                          stacked
      >
        <label v-for="(topic, index) in aiTopics"
               :key="`radio_${index}`"
               :for="`radio_${index}`"
               class="p-2 d-block rounded cursor-pointer"
               :class="{ 'border-primary': topic.value === topicModal.item.type, 'border': topic.value !== topicModal.item.type }"
        >
          <b-form-radio :id="`radio_${index}`"
                        :value="topic.value"
                        :disabled="topics.find(t => t.type === topic.value)"
          >
            <div class="text-primary font-weight-bolder">
              <feather-icon :icon="topic.icon" />
              {{ $t(topic.label) }}
            </div>
            <small>{{ $t(topic.text) }}</small>
          </b-form-radio>
        </label>
      </b-form-radio-group>
      <!--      <sw-select :name="$t('task.Type')">-->
      <!--        <v-select-->
      <!--          v-model="topicModal.item.type"-->
      <!--          :options="aiTopics"-->
      <!--          :reduce="i => i.value"-->
      <!--          :selectable="t => !topics.find(topic => topic.type === t.value)"-->
      <!--        >-->
      <!--          <template #option="{ label }">-->
      <!--            {{ $t(label) }}-->
      <!--          </template>-->
      <!--          <template #selected-option="{ label }">-->
      <!--            {{ $t(label) }}-->
      <!--          </template>-->
      <!--          <template #no-options>-->
      <!--            {{ $t('NoOptions') }}-->
      <!--          </template>-->
      <!--        </v-select>-->
      <!--      </sw-select>-->
      <b-button
        class="mt-50"
        size="sm"
        variant="primary"
        :disabled="!topicModal.item.type"
        @click="saveTopicItem"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}</b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormTextarea, VBTooltip, BFormRadioGroup, BFormCheckboxGroup,
} from 'bootstrap-vue'
import EditAiConnectionData from '@/pages/settings/ai/components/EditAiConnectionData.vue'

import axiosIns from '@/libs/axios'
import { aiTopics, aiTopicsByValue } from '@/@types/aiTypes'
import fileIcon from '@/@data/fileIcons'
import { UPLOAD_FILES } from '@/@constants/mutations'
import AddSalesAiFileModal from '@/views/settings/ai/AddSalesAiFileModal.vue'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import { formatDate } from '../../../@core/utils/filter'

export default {
  name: 'EditAiConnection',
  components: {
    vSelect,
    AddSalesAiFileModal,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckboxGroup,
    EditAiConnectionData,
    VueSlider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    loading: false,
    showFileModal: false,
    salesAiFiles: [],
    salesAiFilesLoading: false,
    itemSelectedSalesAiFileIds: [],
    item: {
      // Confirmed
      name: '',
      negSentimentProbThreshold: 0.992,
      similarityDistanceLimit: 0.32,
      maxRetries: 5,
      kSimilarChunks: 1,
      messageCountConfirmationThreshold: 8,
      maxTokens: 1000,
      model: 'gpt-4',
      repeatType: 'ONCE', // ALWAYS
      files: [],
      // Old
      temperature: 1,
      topP: 1,
      visionEnabled: false,
      instructions: `1. Jesteś asystentem firmy i posiadasz poniżej wskazane kompetencje:
  - (wpisz kompetencje)
  - (wpisz kompetencje)
  - (wpisz kompetencje)
  - (wpisz kompetencje)
2. Musisz zawsze wczytać wiedzę z wszystkich plików dołączonych do asystenta przed udzieleniem każdej odpowiedzi użytkownikowi.
3. Nie wolno Ci odpowiadać na podstawie danych, których nie znajdziesz w załączonych do asystenta plikach.
4. Nie wolno Ci informować, że korzystasz z załączonych plików. Napisz tylko, że nie masz kompetencji w tym zakresie i podaj przykłady w jakich sprawach użytkownik może pisać.
5. Nie wolno Ci nigdy podawać przypisów w odpowiedziach.
6. Staraj się odpowiadać krótko, zwięźle i na temat.
7. Informuj, że jesteś asystentem sztucznej inteligencji.
      `,
      effectOfIntegration: '',
      modules: '',
      data: [
        {
          file: '', text: 'Testowa treść pliku', url: '', type: 'TEXT', createdAt: { date: new Date() },
        },
      ],
    },

    // TOPIC
    aiTopicsByValue,
    aiTopics,
    topics: [],
    topicModal: {
      show: false,
      index: -1,
      item: {
        type: '',
      },
    },

    data: {
      files: [],
      text: '',
      url: '',
    },

    showQandAModal: false,
    showTextModal: false,
    showUrlModal: false,
    showPdfModal: false,

    effects: [{ label: 'Generowanie rekordu reklamacji', value: 'COMPLAINT' }, { label: 'Informacje systemowe', value: 'SYSTEM' }],
    modules: [
      {
        label: 'Produkty',
        value: 'PRODUCTS',
        options: [
          { label: 'Nazwa' },
          { label: 'Kategoria' },
          { label: 'Podkategoria' },
          { label: 'Cena brutto' },
        ],
      },
    ],
    options: [
      { label: 'Imię' },
      { label: 'Nazwisko' },
      { label: 'Opis szkody' },
      { label: 'Numer zamówienia' },
    ],

    config: [],
  }),
  computed: {
    isEdited() {
      return Boolean(this.$route.params?.id) && this.$route.params.id !== 'new'
    },
    allowFiles() {
      return !['gpt-4', 'gpt-4-0613', 'gpt-3.5-turbo-16k-0613', 'gpt-3.5-turbo-16k', 'gpt-3.5-turbo-0613'].includes(this.item.model)
    },
  },
  async mounted() {
    await Promise.all([
      this.loadSalesAiFiles(),
    ])

    if (this.isEdited) await this.loadItem()
  },
  methods: {
    fileIcon,
    formatDate,
    async addNewSalesAiFile(fileResponse) {
      if (!fileResponse.data.data.items?.length) return

      const { id } = fileResponse.data.data.items[0]
      if (!this.itemSelectedSalesAiFileIds.includes(id)) this.itemSelectedSalesAiFileIds.push(id)

      await this.loadSalesAiFiles()
    },
    getTypeIcon(type) {
      if (type === 'TEXT') return 'Edit3Icon'
      if (type === 'PDF') return 'LinkIcon'
      if (type === 'URL') return 'FileTextIcon'
      if (type === 'QAA') return 'AlertCircleIcon'

      return 'AlertCircleIcon'
    },
    async loadSalesAiFiles() {
      this.salesAiFilesLoading = true
      try {
        const [respFiles] = await Promise.all([
          axiosIns.get('1/ai/salesai/files', { params: { fields_load: 'BASE_AI_FILES' } }),
        ])

        if (respFiles.data.data?.items) {
          this.$set(this, 'salesAiFiles', (respFiles.data.data?.items || []).map(e => ({ ...e, loading: false })))
        }

        return respFiles.data
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return []
      } finally {
        this.salesAiFilesLoading = false
      }
    },
    closeModals() {
      this.showQandAModal = false
      this.showTextModal = false
      this.showUrlModal = false
      this.showPdfModal = false
      this.data = {
        files: [],
        text: '',
        url: '',
      }
    },

    async loadItem(newItemId = null) {
      const { id } = this.$route.params
      if (!id && !newItemId) return [null, 'Required id param']

      this.loading = true

      try {
        const params = {
          fields_load: 'AI_ENVIRONMENT',
          eq_id: newItemId || id,
        }

        const [envResponse, topicResponse] = await Promise.all([
          axiosIns.get('1/ai/salesai/environments', { params }),
          axiosIns.get(`1/ai/salesai/environments/${id}/topics`, { params: { fields_load: 'AI_ENVIRONMENT_TOPIC' } }),
        ])

        // eslint-disable-next-line prefer-destructuring
        this.item = envResponse.data.data.items[0]
        this.item.topP = this.item.topP !== null ? this.item.topP : 1
        this.itemSelectedSalesAiFileIds = (this.item?.salesAiFiles || []).mapKeys('id')

        this.topics = topicResponse.data.data.items?.length ? topicResponse.data.data.items : this.item.salesAiEnvironmentTopics

        return [envResponse.data.data.item, null]
      } catch (err) {
        return [null, err]
      } finally {
        this.loading = false
      }
    },

    async saveItem() {
      this.loading = true
      this.$nextTick(async () => {
        const itemCopy = JSON.parse(JSON.stringify(this.item))

        itemCopy.temperature = Number(Number(itemCopy.temperature).toFixed(2))
        itemCopy.topP = Number(Number(itemCopy.topP).toFixed(2))
        itemCopy.similarityDistanceLimit = Number(Number(itemCopy.similarityDistanceLimit).toFixed(3))
        itemCopy.negSentimentProbThreshold = Number(Number(itemCopy.negSentimentProbThreshold).toFixed(3))

        if (this.item.files) {
          const toUpload = this.item.files.filter(file => !file?.token)
          itemCopy.files = this.item.files.filter(file => file?.token).mapKeys('token')

          if (toUpload.length) {
            const filesPayload = {
              uploadedFiles: toUpload,
              type: 'building',
            }
            const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)
            itemCopy.files.push(...files)
          }
        }

        itemCopy.salesAiFiles = this.allowFiles ? this.itemSelectedSalesAiFileIds.mapKeys('id').filter(Boolean) : []

        delete itemCopy.data
        delete itemCopy.effectOfIntegration
        delete itemCopy.modules
        delete itemCopy.createdAt
        delete itemCopy.createdBy
        delete itemCopy.updatedAt
        delete itemCopy.updatedBy
        delete itemCopy.salesAiChannels
        delete itemCopy.channelsCount

        itemCopy.salesAiEnvironmentTopics = this.topics.map(t => {
          if (t.id) return { id: t.id, type: t.type }

          return { type: t.type }
        })

        /* eslint-disable */
        // eslint-disable-next-line no-unused-vars
        // const [responses, _] = await this.saveTopics()
        // const [updateResponse, createResponse] = responses
        // console.log(updateResponse, createResponse)
        // return

        // eslint-disable-next-line no-unreachable
        try {
          const response = !this.isEdited
            ? await axiosIns.post('1/ai/salesai/environments', itemCopy)
            : await axiosIns.patch('1/ai/salesai/environments', itemCopy)

          const envId = response.data.data.items[0]?.id

          this.showToast('success', this.$i18n.t('success.contactUpdated'))

          if (!this.isEdited && envId) {
            this.$router.push({ name: 'edit-ai-connection', params: { id: envId } })
          }

          await this.loadItem(envId)

          return [response, null]
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          return [null, err]
        } finally {
          this.loading = false
        }
      })
    },
    // TOPIC
    openTopicModal(index = -1, topicItem = {
      type: '',
    }) {
      this.$set(this, 'topicModal', {
        show: true,
        index,
        item: { ...topicItem },
      })
    },
    closeTopicModal() {
      this.$set(this, 'topicModal', {
        show: false,
        index: -1,
        item: {
          type: '',
        },
      })
    },
    async removeTopic(topic, index) {
      // const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      // if (!confirmed) return

      // if (topic.id) {
      //   try {
      //     const { id } = this.$route.params
      //     await axiosIns.delete(`1/ai/salesai/environments/${id}/topics`, { data: JSON.stringify([topic.id]) })
      //   } catch (err) {
      //     this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      //   }
      // }

      this.$delete(this.topics, index)
    },
    async removeFile(file, index) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      this.$delete(this.item.files, index)
    },
    saveTopicItem() {
      const { item, index } = { ...this.topicModal }

      if (index > -1) this.$set(this.topics, index, { ...item })
      else this.topics.push({ ...item })

      this.closeTopicModal()
    },
    async saveTopics() {
      // salesAiEnvironment: envId
      try {
        // eslint-disable-next-line no-underscore-dangle
        const _topics = this.topics.map(t => ({ id: t.id, type: t.type }))

        const toUpdate = _topics.filter(topic => topic.id)
        const toCreate = _topics.filter(topic => !topic.id).map(topic => {
          const t = topic
          delete t.id

          return t
        })

        const promises = []
        if (toUpdate.length) promises.push(axiosIns.patch('1/ai/salesai/environments/topics', toUpdate))
        if (toCreate.length) promises.push(axiosIns.post('1/ai/salesai/environments/topics', toCreate))

        const [updateResponse, createResponse] = await Promise.all(promises)

        return [[updateResponse, createResponse], null]
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return [null, err]
      }
    },
    async pushNewFiles(files = []) {
      this.item.files.push(...files)
      this.data.files = []
    },
  },
}
</script>

<style scoped>

</style>
